<template>
  <div class="vg_wrapper">
    <el-card>
      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getPpotsNow()">
          <el-row>
            <el-col :md="6">
              <el-form-item label="合同编号：">
                <el-input size="small" v-model.trim="searchForm.pcon_no" clearable placeholder="请填写合同编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="产品货号：">
                <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写产品编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="产品名称：">
                <el-input size="small" v-model.trim="searchForm.prod_name" clearable placeholder="请填写产品名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="6">
              <el-form-item label="核销状态：">
                <el-select v-model="searchForm.ppot_full" placeholder="请选择" size="small" clearable>
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="14">
              <el-form-item label="待出库时间：">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getPpotsNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" border>
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="合同编号" prop="pcon_no" />
            <el-table-column label="产品货号" prop="prod_no" />
            <el-table-column label="产品名称" prop="prod_name" />
            <el-table-column label="计划出库" prop="pcon_prod_num"/>
            <el-table-column label="实出库数" prop="ppot_num"/>
            <el-table-column label="待出库时间" prop="ppot_date" :formatter="formatDate" />
            <el-table-column label="核销状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="getStatusName(scope.row.ppot_full).type" size="mini">{{getStatusName(scope.row.ppot_full).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {ppotAPI} from "@api/modules/ppot";
import pubPagination from "@/components/common/pubPagination";

export default {
  name: "PpotList",
  components: {
    pubPagination
  },
  data(){
    return{
      searchForm:{
        pcon_no:'',
        prod_no:'',
        prod_name:'',
        ppot_full:'',
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      btn:{},
      options:[
        {
          value: 0,
          label: '未核销'
        },{
          value: 1,
          label: '已核销'
        }
      ]
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/ppot_add' || from.path === '/ppot_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.getPpotsList()
    },
    //获取物料采购列表
    getPpotsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(ppotAPI.getPpots,
          {
            pcon_no: this.searchForm.pcon_no,
            prod_no: this.searchForm.prod_no,
            prod_name: this.searchForm.prod_name,
            ppot_full: this.searchForm.ppot_full,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 核销状态转换
    getStatusName(status) {
      if(status===0) {
        return {name:'未核销',type:'info'}
      } else if (status===1) {
        return {name:'已核销',type:'warning'};
      }
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        pcon_no:'',
        prod_no:'',
        prod_name:'',
        ppot_full:'',
        timeValue:{
          startTime:null,
          endTime:null
        }
      };
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.ppot_date)
    },
    // 查询方法
    getPpotsNow(){
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getPpotsList()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getPpotsList()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.ppot_id)
      })
      post(ppotAPI.destroyPpotByIds,{'ppot_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
  }
}
</script>

<style scoped>

</style>